import { useState } from "react"
import { Button } from "src/components/Button"
import { Grid } from "src/components/Grid"
import Image from "src/components/Image"
import styled from "styled-components"

const Center = styled.div`
  text-align: center;
`
const Row = styled(Grid)`
  margin: 30px -10px;
  align-items: flex-start;
  justify-content: center;
  .gatsby-image-wrapper {
    width: 100px;
    margin: 0 5px 0;
  }
`

const LIMITED_COUNT = 5

interface Props {
  list: DomainPartner[]
}

const Partners = ({ list }: Props) => {
  const [expanded, setExpanded] = useState(false)

  const limited = !expanded && list.length > LIMITED_COUNT

  const partners = limited ? list.slice(0, LIMITED_COUNT) : list

  return (
    <>
      <Row>
        {partners.map(({ id, logo_image, logo_url, name, url }) => (
          <a href={url} key={id} target="_blank" rel="noreferrer">
            <Image node={logo_image} src={logo_url} alt={name} />
          </a>
        ))}
      </Row>
      {limited && (
        <Center>
          <Button onClick={() => setExpanded(true)}>Tous les partenaires</Button>
        </Center>
      )}
    </>
  )
}

export default Partners

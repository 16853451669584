import { gql } from "@apollo/client"
import { useState } from "react"
import { Button } from "src/components/Button"
import { Input, TextArea } from "src/components/Inputs"
import { COLORS, SIZES } from "src/constants"
import { useClientMutation } from "src/helpers/apollo"
import { handleError } from "src/helpers/bugsnag"
import styled from "styled-components"

const SEND_EMAIL = gql`
  mutation(
    $domainId: ID!
    $name: String!
    $email: String!
    $message: String!
    $type: MailType!
    $association: ID
    $activity: ID
    $mission: ID
  ) {
    sendMailContact(
      domain: $domainId
      fullname: $name
      email: $email
      message: $message
      type: $type
      association: $association
      activity: $activity
      mission: $mission
    ) {
      sent
    }
  }
`

const ResultMessage = styled.p<{ ok: boolean }>`
  text-align: center;
  font-size: ${SIZES.medium}px;
  color: ${({ ok }) => (ok ? COLORS.blue : COLORS.red)};
  margin: 180px 0 160px;
`
const FormContainer = styled.form`
  padding: 20px 0;
  max-width: 500px;
  margin: auto;
  text-align: center;
`
const SubmitButton = styled(Button)`
  margin-top: 10px;
`
const ErrorMessage = styled.p`
  color: ${COLORS.red};
`

interface Values {
  name: string
  email: string
  message: string
}
interface Result {
  sendMailContact: {
    sent: boolean
  }
}

interface Props {
  className?: string // for styling the container with styled-components
  type?: MailType
  association?: ID
  activity?: ID
  mission?: ID
}

const Form = ({ className, ...props }: Props) => {
  const [state, setState] = useState<Values>({
    name: "",
    email: "",
    message: "",
  })
  const [loadingCaptcha, setLoading] = useState(false)

  const { data, loading: loadingQuery, error, execute } = useClientMutation<Result>(SEND_EMAIL)

  const handleChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setState({
      ...state,
      [currentTarget.name]: currentTarget.value,
    })
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)
    grecaptcha.ready(() => {
      grecaptcha.execute(process.env.GATSBY_RECAPTCHA_KEY, { action: "submit" }).then(
        (token) => {
          execute(
            { ...props, ...state },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          setLoading(false)
        },
        (err) => {
          handleError(err)
          alert("Une erreur est survenue. Veuillez réessayer.")
          setLoading(false)
        }
      )
    })
  }

  if (data) {
    const { sent } = data.sendMailContact
    return (
      <ResultMessage ok={sent}>
        {sent ? "Votre message a bien été envoyé." : "Votre message n’a pas été envoyé !"}
      </ResultMessage>
    )
  }

  const loading = loadingCaptcha || loadingQuery

  return (
    <FormContainer onSubmit={handleSubmit} className={className}>
      <Input
        name="name"
        placeholder="Prénom et Nom"
        onChange={handleChange}
        value={state.name}
        aria-label="Prénom et nom"
      />
      <Input
        name="email"
        placeholder="Adresse e-mail"
        type="email"
        onChange={handleChange}
        value={state.email}
        aria-label="Adresse e-mail"
        required
      />
      <TextArea
        name="message"
        placeholder="Votre message"
        rows={10}
        onChange={handleChange}
        value={state.message}
        aria-label="Votre message"
        required
      />
      <SubmitButton disabled={loading}>{loading ? "Envoi..." : "Envoyer"}</SubmitButton>
      {error && <ErrorMessage>Une erreur est survenue. Veuillez réessayer.</ErrorMessage>}
    </FormContainer>
  )
}

export default Form

import { graphql } from "gatsby"
import AssociationCard from "src/cards/AssociationCard"
import { ActivityFiltersSection } from "src/components/ActivityFiltersSection"
import { ButtonLink, ButtonUrl } from "src/components/Button"
import Form from "src/components/Form"
import { Block, Grid, HomeSection, Odd } from "src/components/Grid"
import Image from "src/components/Image"
import Partners from "src/components/Partners"
import Selection from "src/components/Selection"
import Tags from "src/components/Tags"
import { useThemeFromDomainTheme } from "src/components/Theme"
import { AssociationFilterType } from "src/constants/filters"
import Layout from "src/layout"
import styled from "styled-components"

const About = styled(Grid)`
  margin: 30px -10px 0;
  > div {
    flex: 1;
    margin: 10px;
    min-width: 280px;
  }
`
const Center = styled.div`
  margin-top: 20px;
  text-align: center;
`
const MobileCenter = styled.div`
  @media (max-width: 619px) {
    text-align: center;
  }
`

interface Data {
  assolib: {
    allDomains: Domain[]
    allDomainTagCounts: DomainTagCount[]
    allDomainPartners: DomainPartner[]
    allAssociations: Association[]
  }
}

const HomePage: GatsbyPage<Data> = (props) => {
  const {
    data: { assolib },
  } = props
  const domain = assolib.allDomains[0]
  const theme = useThemeFromDomainTheme(domain.theme)
  const updated = assolib.allAssociations.filter(({ absolute }) => absolute)

  return (
    <Layout theme={theme} domain={domain}>
      <ActivityFiltersSection />
      <Selection />
      <Odd>
        <HomeSection>
          <h2>Activités les plus recherchées</h2>
          <Tags list={assolib.allDomainTagCounts} />
        </HomeSection>
      </Odd>
      <HomeSection>
        <About>
          <Image node={domain.home_about_img} src={domain.home_about_image} />
          <div>
            <h2>{domain.home_about_title}</h2>
            <p>{domain.home_about_text}</p>
            {domain.home_about_url && (
              <MobileCenter>
                <ButtonUrl href={domain.home_about_url} target="_blank" rel="noopener">
                  Voir plus
                </ButtonUrl>
              </MobileCenter>
            )}
          </div>
        </About>
      </HomeSection>
      <Odd>
        <HomeSection>
          <h2>Dernières associations mises à jour</h2>
          <Grid>
            {updated.map((association) => (
              <Block $col={3} key={association.id}>
                <AssociationCard association={association} type={AssociationFilterType.Activity} />
              </Block>
            ))}
          </Grid>
          <Center>
            <ButtonLink to="/associations/">Toutes les associations</ButtonLink>
          </Center>
        </HomeSection>
      </Odd>
      <HomeSection>
        <h2>Vous ne trouvez pas votre association ? Dites-le nous !</h2>
        {domain?.contact_form_url ? (
          <Center>
            <ButtonLink to={domain.contact_form_url}>Contactez-nous</ButtonLink>
          </Center>
        ) : (
          <Form type="CONTACT_ASSOLIB" />
        )}
      </HomeSection>
      {assolib.allDomainPartners.length > 0 && (
        <Odd>
          <HomeSection>
            <h2>{domain.partner_banner_title || "Partenaires"}</h2>
            <Partners list={assolib.allDomainPartners} />
          </HomeSection>
        </Odd>
      )}
    </Layout>
  )
}

export default HomePage

export const query = graphql`
  query {
    assolib {
      allDomainTagCounts {
        tag {
          id
          name
        }
      }
      allDomains {
        outlink_url
        home_about_image # needed for home_about_img to work!
        home_about_img {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        facebook_url
        twitter_url
        instagram_url
        home_about_title
        home_about_text
        home_about_url
        legal_notice_url
        contact_form_url
        partner_banner_title
        ...DomainThemeField
      }
      allDomainPartners {
        id
        name
        url
        logo_url
        logo_image {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      allAssociations(sortField: "updatedAt", type: Mission, sortOrder: DESC, perPage: 3) {
        ...SearchAssociationFields
      }
    }
  }
`

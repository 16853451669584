import { COLORS, SIZES } from "src/constants"
import styled, { css } from "styled-components"

const base = css`
  border: 1px solid ${COLORS.inputBorder};
  border-radius: 2px;
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
  font-size: ${SIZES.small}px;
  display: block;

  ::placeholder {
    color: ${COLORS.placeholder};
    font-weight: 300;
  }
`

export const Input = styled.input`
  ${base}
`

export const TextArea = styled.textarea`
  ${base}
`
